import * as React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const ContactList = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 65px;
  list-style: none;
  margin: 0 auto 35px;
  max-width: 600px;
  padding: 0;

  @media (min-width: 960px) {
    grid-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    max-width: none;
  }
`;

const ContactListItem = styled.li`
  transition: border-color 200ms ease-in;
  border: 1px solid rgba(199,87,132,0.15);
  border-radius: 3px;
  padding: 25px 7px 5px;
  text-align: center;

  &:hover {
    border-color: #c75784;
  }

  figure {
    min-height: 80px;
    margin: -43px 0 -15px;
  }

  svg {
    background: #3c1638;
    fill: #5dd882;
    margin-bottom: 0;
    max-width: 35px;
    width: 100%;

    &[data-icon="mobile-alt"] {
      max-width: 25px;
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 21px;
    max-width: 210px;
  }

  dt {
    color: #fff;
    font-weight: 400;
    margin: 0;
    width: 80px;
  }

  dd {
    font-weight: 600;
    margin: 0;
    width: calc(100% - 90px);
  }

  p {
    margin: 0 auto 25px;
    max-width: 320px;
  }

  a {
    font-size: 1.8rem;
    font-weight: 600;
  }
`;

const siteMetadata = {
  title: 'Skup numizmatów, skup monet i banknotów - Monety Olsztyn',
  description: 'Chcesz szybko wycenić monety, banknoty lub odznaczenia wojskowe? Zapraszamy do kontaktu: 507 506 476 lub kontakt@monety.olsztyn.pl',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/kontakt/',
  locale: 'pl_PL',
};

const KontaktPage = () => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <h1
      style={{
        padding: '25px 0 75px',
        textAlign: 'center',
      }}
    >
      Zapraszamy do&nbsp;kontaktu
    </h1>

    <ContactList>
      <ContactListItem>
        <figure>
          <svg width="25" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>
        </figure>
        <p>Masz pytanie? Wyślij do nas e-mail, jeśli&nbsp;to&nbsp;możliwe dołącz też&nbsp;zdjęcia.</p>
        <p>
          <a href="mailto:kontakt@monety.olsztyn.pl">kontakt@monety.olsztyn.pl</a>
        </p>
      </ContactListItem>

      <ContactListItem>
        <figure>
          <svg width="42" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="svg-inline--fa fa-map-marker-alt fa-w-12 fa-3x"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
        </figure>
        <p><strong>Monety Olsztyn, Centrum</strong></p>
        <p><em>Przyjmujemy wyłącznie po uprzednim umówieniu&nbsp;spotkania.</em></p>
      </ContactListItem>

      <ContactListItem>
        <figure>
          <svg width="25" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mobile-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"></path></svg>
        </figure>
        <dl>
          <dt>pon–pt:</dt>
          <dd>10:00–18:00</dd>

          <dt>sobota:</dt>
          <dd>10:00–15:00</dd>
        </dl>
        <p><a href="tel:+48507506476">+48 507 506 476</a></p>
      </ContactListItem>
    </ContactList>

    <div id="map_canvas"></div>

    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d75425.69131741961!2d20.393600989716592!3d53.78854684169579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e27f4c6faafeed%3A0x9713692e989911dd!2sSkup%20monet%20i%20banknot%C3%B3w%20Olsztyn!5e0!3m2!1spl!2spl!4v1654097855833!5m2!1spl!2spl"
      width="100%"
      height="450"
      allowFullScreen=""
      loading="lazy"
      title="Wycena monet na terenie Olsztyna i okolic"
      style={{ border: '0' }}
    >
    </iframe>
  </>
);

export default KontaktPage;
